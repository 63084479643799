import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DocumentContext } from "~context/DocumentContext";

const ScreenHeight = ({ children, className, scale }) => {
  const { windowHeight, windowWidth } = useContext(DocumentContext);
  const [height, setHeight] = useState(100);

  useEffect(() => {
    if (typeof window === `undefined`) {
      return;
    }

    let detectedHeight =
      document?.documentElement?.clientHeight || window.innerHeight;

    if (scale !== 1) {
      detectedHeight *= scale;
    }

    setHeight(detectedHeight);
  }, [windowHeight, windowWidth]);

  //

  return (
    <section className={className} style={{ height }}>
      {children}
    </section>
  );
};

ScreenHeight.defaultProps = {
  scale: 1
};

ScreenHeight.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  scale: PropTypes.number
};

export default ScreenHeight;
