import React, { useContext, useEffect } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";
import CursorHoverable from "~components/CursorHoverable";
import ArrowUp from "~components/svg/ArrowUp";
import Wordmark from "~components/svg/Wordmark";

const Header = ({ clipped }) => {
  const {
    headerColor,
    headerText,
    setHeaderText,
    menuActive,
    setMenuActive,
    notificationActive,
    pathname
  } = useContext(AppContext);
  const { device, scrollTop, windowWidth } = useContext(DocumentContext);

  //
  // scroll link

  let backToTop = false;

  if (device && device === `desktop` && scrollTop > windowWidth * 0.4) {
    backToTop = true;
  }

  const scrollToTop = () => {
    if (typeof window === `undefined`) {
      return;
    }

    window.scrollTo({
      top: 0,
      behavior: `smooth`
    });
  };

  //
  // color management

  let foregroundColor = headerColor;

  if (pathname?.includes(`case-studies/`)) {
    foregroundColor = `white`;
  } else if (pathname?.includes(`archive/`)) {
    foregroundColor = `black`;
  } else {
    switch (pathname) {
      case `/`:
      case `/contact`:
      case `/contact/`:
        foregroundColor = `white`;
        setHeaderText(null);

        break;

      case `/work`:
      case `/work/`:
        break;

      default:
        setHeaderText(null);
        foregroundColor = `black`;
        break;
    }
  }

  let backgroundColor = `transparent`;

  if (device && device !== `desktop`) {
    if (menuActive) {
      backgroundColor = `black`;
      foregroundColor = `white`;
    } else {
      switch (pathname) {
        case `/`:
          backgroundColor = `off-white`;
          foregroundColor = `black`;

          break;

        case `/contact`:
        case `/contact/`:
          backgroundColor = `black`;
          foregroundColor = `white`;

          break;

        case `/work`:
        case `/work/`:
          foregroundColor = headerColor;

          if (foregroundColor === `white`) {
            backgroundColor = `black`;
          } else {
            backgroundColor = `off-white`;
          }

          break;

        default:
          backgroundColor = `white`;
          foregroundColor = `black`;

          break;
      }
    }
  }

  //

  const innerJSX = (
    <>
      <button
        type="button"
        className="desktop-only absolute top-0 right-0 mr-v2"
        onClick={scrollToTop}
      >
        <CursorHoverable className="header__back w-full h-full relative flex bg-white text-black b1">
          <span className="mr-v1">Back To Top</span>
          <ArrowUp />
        </CursorHoverable>
      </button>

      <nav className="header__nav w-full relative z-10 flex items-end xs:items-center justify-between">
        <div className="flex items-center">
          <CursorHoverable>
            <Link
              to="/"
              className="flex items-center"
              onClick={() => setMenuActive(false)}
            >
              <Wordmark
                className="header__logo mr-v1"
                color={foregroundColor}
              />

              {headerText && (
                <div className="touch-only">
                  <span className="-mb-1 mx-2 caption">✕</span>
                  <span className="b2">{headerText}</span>
                </div>
              )}
            </Link>
          </CursorHoverable>
        </div>

        <ul
          className={`header__links desktop-only relative flex justify-end text-${foregroundColor}`}
        >
          <li className="ml-v3">
            <CursorHoverable>
              <Link to="/about" className="hover-underline">
                About
              </Link>
            </CursorHoverable>
          </li>

          <li className="ml-v3">
            <CursorHoverable>
              <Link to="/work" className="hover-underline">
                Work
              </Link>
            </CursorHoverable>
          </li>

          <li className="ml-v3">
            <CursorHoverable>
              <a
                href="https://exhibition.with-you.studio"
                className="hover-underline"
              >
                Exhibition
              </a>
            </CursorHoverable>
          </li>

          <li className="ml-v3">
            <CursorHoverable>
              <Link to="/contact" className="hover-underline">
                Contact
              </Link>
            </CursorHoverable>
          </li>
        </ul>

        <button
          type="button"
          className="header__menu touch-only"
          onClick={() => setMenuActive(!menuActive)}
        >
          <ul className="w-5 h-5 relative flex flex-col justify-between">
            <li
              className={`header__menu__line header__menu__line--0 w-full relative bg-${foregroundColor}`}
              style={{ height: `2px` }}
            ></li>
            <li
              className={`header__menu__line header__menu__line--1 w-full relative bg-${foregroundColor}`}
              style={{ height: `2px` }}
            ></li>
            <li
              className={`header__menu__line header__menu__line--2 w-full relative bg-${foregroundColor}`}
              style={{ height: `2px` }}
            ></li>
          </ul>
        </button>
      </nav>
    </>
  );

  //

  return (
    <header
      className={`header ${backToTop ? `back-to-top` : ``} ${
        clipped ? `clipped z-50` : `z-40`
      } ${menuActive ? `menu-active` : ``} ${
        notificationActive ? `notification-active` : ``
      } w-full fixed top-0 right-0 left-0 pt-v2 pr-v3 pl-v3 pb-v1 ${foregroundColor} bg-${backgroundColor}`}
    >
      {innerJSX}
    </header>
  );
};

Header.defaultProps = {
  clipped: false,
  color: null
};

Header.propTypes = {
  clipped: PropTypes.bool,
  color: PropTypes.string
};

export default Header;
