/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useContext } from "react";
import { graphql, Link, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import { AppContext } from "~context/AppContext";
import ScreenHeight from "~components/ScreenHeight";
import Video from "~components/Video";
import { parseProjects } from "~utils/helpers";

const query = graphql`
  query Nav {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "case-study-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            client
            priority
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 1440, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
              publicURL
            }
            featuredImageAlt
            featuredVideoURL
            featuredVideoControls
          }
        }
      }
    }
  }
`;

const Nav = () => {
  const { menuActive, setMenuActive, setWorkLink } = useContext(AppContext);

  return (
    <StaticQuery
      query={query}
      render={data => {
        const { projects } = parseProjects(data);

        const featuredProject = projects?.[0];
        let mediaJSX;

        if (featuredProject) {
          if (
            featuredProject?.frontmatter?.featuredVideoURL &&
            featuredProject.frontmatter.featuredVideoURL !== ``
          ) {
            mediaJSX = (
              <Video
                className="w-full h-full relative block object-cover"
                src={featuredProject.frontmatter.featuredVideoURL}
                controls={
                  featuredProject.frontmatter.featuredVideoControls || false
                }
              />
            );
          } else if (
            featuredProject?.frontmatter?.featuredImage?.childImageSharp?.fluid
          ) {
            mediaJSX = (
              <Img
                className="w-full h-full relative block object-cover"
                fluid={
                  featuredProject.frontmatter.featuredImage.childImageSharp
                    .fluid
                }
                alt={featuredProject.frontmatter.featuredImageAlt}
              />
            );
          } else if (featuredProject?.frontmatter?.featuredImage?.publicURL) {
            mediaJSX = (
              <img
                className="w-full h-full relative block object-cover"
                src={featuredProject.frontmatter.featuredImage.publicURL}
                alt={featuredProject.frontmatter.featuredImageAlt}
              />
            );
          }
        }

        return (
          <ScreenHeight
            className={`mobile-menu ${
              menuActive ? `active` : ``
            } touch-only fixed top-0 right-0 bottom-0 left-0 z-30 bg-black text-white`}
          >
            {menuActive && (
              <nav className="w-full h-full flex flex-col justify-between pt-v24">
                <div className="grid">
                  <ul className="grid-end-24">
                    <li className="animation-appear-down animation-delay-1 f5">
                      <Link
                        to="/about"
                        className="block pt-v3 pb-v3"
                        onClick={() => setMenuActive(false)}
                      >
                        About
                      </Link>
                    </li>

                    <li className="animation-appear-down animation-delay-2 f5">
                      <Link
                        to="/work"
                        className="block pt-v3 pb-v3"
                        onClick={() => {
                          setWorkLink(`Recent Projects`);
                          setMenuActive(false);
                        }}
                      >
                        Work
                      </Link>
                    </li>

                    <li className="animation-appear-down animation-delay-3 f5">
                      <a
                        href="https://exhibition.with-you.studio"
                        className="block pt-v3 pb-v3"
                      >
                        Exhibition
                      </a>
                    </li>

                    <li className="animation-appear-down animation-delay-4 f5">
                      <Link
                        to="/contact"
                        className="block pt-v3 pb-v3"
                        onClick={() => setMenuActive(false)}
                      >
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="grid">
                  {featuredProject && (
                    <Link
                      to={featuredProject.fields.slug}
                      className="grid-end-24 animation-appear animation-delay-5 mb-v8"
                      onClick={() => setMenuActive(false)}
                    >
                      <h3 className="mb-v4 b1">Featured project:</h3>

                      <div className="w-full sixteen-by-nine--padded relative overflow-hidden">
                        <div className="w-full h-full absolute transform-center">
                          {mediaJSX && mediaJSX}
                        </div>
                      </div>

                      <div className="w-full relative mt-v4 flex justify-between">
                        <h4 className="b1">
                          {featuredProject.frontmatter.client}
                        </h4>

                        <p className="b1 underline">View Project</p>
                      </div>
                    </Link>
                  )}
                </div>
              </nav>
            )}
          </ScreenHeight>
        );
      }}
    />
  );
};

export default Nav;
