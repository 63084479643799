/* eslint-disable react/prop-types */

import React, { useContext } from "react";
import PropTypes from "prop-types";
import { AppContext } from "~context/AppContext";

const CursorHoverable = ({ children, className }) => {
  const { setCursorMode } = useContext(AppContext);

  return (
    <div
      role="presentation"
      className={`cursor-hoverable ${className}`}
      onMouseEnter={() => setCursorMode(`focused`)}
      onMouseLeave={() => setCursorMode(null)}
    >
      {children}
    </div>
  );
};

CursorHoverable.defaultProps = {
  className: ``
};

CursorHoverable.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default CursorHoverable;
