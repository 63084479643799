import React, { useContext } from "react";
import PropTypes from "prop-types";
import { AppContext } from "~context/AppContext";
import Cursor from "~components/Cursor";
import Header from "~components/Header";
import Nav from "~components/Nav";
import Notification from "~components/Notification";

const Layout = ({ children, className, style }) => {
  const { notificationActive } = useContext(AppContext);

  //

  return (
    <>
      <Cursor />

      <Header />

      <Nav />

      <Notification />

      <main
        id="layout"
        role="presentation"
        className={`layout ${className} ${
          notificationActive ? `notification-active` : ``
        }`}
        style={style}
      >
        {children}
      </main>
    </>
  );
};

Layout.defaultProps = {
  style: {}
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  style: PropTypes.shape({})
};

export default Layout;
