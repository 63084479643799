import React from "react";
import PropTypes from "prop-types";

const ArrowUp = ({ className, color }) => (
  <svg className={className} viewBox="0 0 12 18" fill="none">
    <path
      d="M11.6569 5.96219L10.2394 7.37309L6.98446 4.10285L6.97077 17.7079L4.97077 17.7059L4.98442 4.13805L1.75416 7.35323L0.343262 5.93571L6.01334 0.292112L11.6569 5.96219Z"
      fill={color}
    />
  </svg>
);

ArrowUp.defaultProps = {
  className: ``,
  color: `black`
};

ArrowUp.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

export default ArrowUp;
