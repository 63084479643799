/* eslint-disable react/prop-types */

import React, { useContext, useEffect } from "react";
import { AppContext } from "~context/AppContext";
import { CursorContext } from "~context/CursorContext";

const Cursor = () => {
  const {
    cursorBackground,
    cursorMode,
    cursorVisible,
    setCursorVisible,
    pathname
  } = useContext(AppContext);
  const { cursorPositionX, cursorPositionY } = useContext(CursorContext);

  let borderColor;

  // if (pathname === `/about`) {
  //   borderColor = `white`;
  // }

  //

  useEffect(() => {
    if (typeof window === `undefined`) {
      return;
    }

    window.addEventListener(`blur`, () => {
      setCursorVisible(false);
    });

    window.addEventListener(`focus`, () => {
      setCursorVisible(true);
    });
  }, []);

  useEffect(() => {
    if (!cursorVisible) {
      setCursorVisible(true);
    }
  }, [cursorPositionX, cursorPositionY]);

  //

  return (
    <div
      className={`cursor ${cursorMode ? `cursor--${cursorMode}` : ``} ${
        cursorVisible ? `` : `opacity-0`
      } transition-opacity fixed top-0 left-0 z-50 block pointer-events-none overflow-hidden`}
      style={{
        transform: `translate3d(${cursorPositionX}px, ${cursorPositionY}px, 0)`
      }}
    >
      <div
        className={`cursor__bg absolute transform-center bg-${cursorBackground}`}
        style={{ border: `2px solid ${borderColor || `transparent`}` }}
      ></div>
    </div>
  );
};

export default Cursor;
