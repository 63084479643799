import React, { useContext } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { DocumentContext } from "~context/DocumentContext";
import CursorHoverable from "~components/CursorHoverable";
import Wordmark from "~components/svg/Wordmark";

const Footer = () => {
  const { device } = useContext(DocumentContext);

  const { allDataYaml } = useStaticQuery(graphql`
    query Footer {
      allDataYaml {
        edges {
          node {
            acknowledgement

            getInTouch {
              label
              email
            }

            location

            followUs {
              label
              url
            }
          }
        }
      }
    }
  `);

  let footerData;

  if (allDataYaml?.edges?.[0]) {
    allDataYaml.edges.forEach(({ node }) => {
      if (footerData) {
        return;
      }

      if (node?.acknowledgement) {
        footerData = node;
      }
    });
  }

  //

  return (
    <footer className="footer w-full relative pt-v8 xs:pt-v20 bg-coal text-white">
      <div className="grid pb-v4 xs:pt-v4">
        <div className="grid-end-6 sm:grid-end-24 grid-start-1 sm:grid-start-1">
          <p
            className={`${
              device === `desktop` ? `caption` : `b2`
            } whitespace-pre-wrap`}
            style={{ lineHeight: 1.5 }}
          >
            {footerData?.acknowledgement}
          </p>
        </div>

        <div className="grid-end-6 sm:grid-end-24 grid-start-9 sm:grid-start-1 xs:mt-v20">
          <h3 className="desktop-only mb-v2 b1">Get in touch</h3>

          {footerData?.getInTouch?.[0] &&
            footerData.getInTouch.map(getInTouchItem => (
              <div key={getInTouchItem.label}>
                <h4 className="mb-v1 xs:mb-v2 b2 text-purple">
                  {getInTouchItem.label}
                </h4>

                <h4 className="mb-v2 xs:mb-v8 f5">
                  <CursorHoverable>
                    <a href={getInTouchItem.email} className="underline">
                      {getInTouchItem.email}
                    </a>
                  </CursorHoverable>
                </h4>
              </div>
            ))}
        </div>

        <div className="grid-end-6 sm:grid-end-24 grid-start-16 sm:grid-start-1 xs:order-1 xs:mt-v14">
          <h3
            className={`mb-v2 ${device === `desktop` ? `b1` : `b2 text-grey`}`}
          >
            Location
          </h3>

          <p className="f5 whitespace-pre-wrap">{footerData?.location}</p>
        </div>

        <div className="grid-end-3 sm:grid-end-24 sm:grid-start-1 xs:order-0 xs:mb-v8 xs:mt-v14">
          <h3
            className={`mb-v2 ${device === `desktop` ? `b1` : `b2 text-grey`}`}
          >
            Follow us
          </h3>

          {footerData?.followUs?.[0] &&
            footerData.followUs.map(followUsItem => (
              <div key={followUsItem.label}>
                <CursorHoverable>
                  <a
                    href={followUsItem.url}
                    className="hover-underline block mb-v1 xs:mb-v3 f5"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {followUsItem.label}
                  </a>
                </CursorHoverable>
              </div>
            ))}
        </div>
      </div>

      <div className="w-full relative bg-coal text-white">
        {device && device === `desktop` && (
          <div className="relative flex items-end justify-between p-v2">
            <div>
              <CursorHoverable>
                <Link to="/">
                  <Wordmark className="footer__logo" color="white" />
                </Link>
              </CursorHoverable>

              <h4 className="b2">© 2020</h4>
            </div>

            <h4 className="footer__sva block caption">
              <span>Built with: </span>

              <CursorHoverable className="inline-block">
                <a
                  href="https://www.instagram.com/sumvalueadded/"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="underline"
                >
                  Sum Value Added
                </a>
              </CursorHoverable>
            </h4>
          </div>
        )}

        {device && device !== `desktop` && (
          <div className="grid items-end mt-v12 pt-v20">
            <div className="grid-end-12 sm:grid-end-24 relative xs:flex xs:flex-col xs:items-center xs:justify-center">
              <Link to="/">
                <Wordmark className="footer__logo" color="white" />
              </Link>

              <h4 className="mt-v2 b2">© 2020</h4>
            </div>

            <h4
              className={`grid-end-24 mt-v20 xs:mt-v16 mb-v8 ${
                device === `desktop` ? `b2` : `caption`
              } text-center`}
            >
              Built with: Sum Value Added
            </h4>
          </div>
        )}
      </div>
    </footer>
  );
};

export default Footer;
