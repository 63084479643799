import React, { useContext, useState } from "react";
import { graphql, StaticQuery } from "gatsby";
import moment from "moment-timezone";
import { AppContext } from "~context/AppContext";

const query = graphql`
  query Notification {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "exhibition-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            isActive
            title
            date
            eventTime
            eventTimezone
            url
          }
        }
      }
    }
  }
`;

const Notification = () => {
  const { notificationActive, setNotificationActive } = useContext(AppContext);
  const [daysLeft, setDaysLeft] = useState(`...`);

  //

  return (
    <StaticQuery
      query={query}
      render={({ allMarkdownRemark }) => {
        let frontmatter;

        if (allMarkdownRemark?.edges?.[0].node) {
          ({ frontmatter } = allMarkdownRemark.edges[0].node);
        }

        setNotificationActive(frontmatter.isActive);

        if (
          !frontmatter.isActive ||
          !frontmatter.title ||
          !frontmatter.date ||
          !frontmatter.eventTime ||
          !frontmatter.eventTimezone ||
          !frontmatter.url
        ) {
          if (notificationActive) {
            setNotificationActive(false);
          }

          return <></>;
        }

        //

        if (daysLeft === `...`) {
          const eventTime = moment(frontmatter.eventTime)
            .tz(frontmatter.eventTimezone)
            .unix();
          const now = moment.tz(frontmatter.eventTimezone).unix();

          const diffTime = eventTime - now;
          const duration = moment.duration(diffTime * 1000, `milliseconds`);

          setDaysLeft(duration.months() * 30 + duration.days());
        }

        //

        const spanJSX = (
          <>
            <span className="caption">With Solace</span>
            <span>—</span>
            <span className="caption">
              An exhibition to raise money for mental health
            </span>
            <span>—</span>
            <span className="caption">SHOP PRINTS NOW</span>

            {/* <span className="caption">
              Exhibition: {frontmatter.title} {frontmatter.date}
            </span> */}
            {/* <span className="caption">Opening in {daysLeft} days</span> */}
            {/* <span className="caption">Register your interest</span> */}
          </>
        );

        const textJSX = (
          <div className="notification__text w-full relative flex items-center justify-between pr-v3 pl-v3">
            {spanJSX}
            <span>—</span>
            {spanJSX}
          </div>
        );

        const marqueeJSX = (
          <div className="animation-marquee-slow h-full flex items-center justify-center gpu">
            {textJSX}
            <span>—</span>
            {textJSX}
          </div>
        );

        return (
          <div className="notification desktop-only w-full fixed top-0 right-0 left-0 z-50 block bg-black text-white">
            <a href={frontmatter.url} rel="noopener noreferrer" target="_blank">
              {marqueeJSX}
            </a>
          </div>
        );
      }}
    />
  );
};

// Notification.defaultProps = {
//   clipped: false,
//   color: null
// };

// Notification.propTypes = {
//   clipped: PropTypes.bool,
//   color: PropTypes.string
// };

export default Notification;
