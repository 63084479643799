import React from "react";
import PropTypes from "prop-types";

const Wordmark = ({ className, color }) => (
  <svg className={className} viewBox="0 0 173 46">
    <path
      d="M19.57,23l-2.19,9.67a.18.18,0,0,1-.18.14H8.54a.21.21,0,0,1-.18-.13L3,13.41a.18.18,0,0,1,.17-.23h8.46a.18.18,0,0,1,.17.15l2.07,10.32a.18.18,0,0,0,.35,0l2.32-10.33a.18.18,0,0,1,.18-.14h7.13a.18.18,0,0,1,.17.14L26.3,23.45a.18.18,0,0,0,.35,0l2.12-10.13a.18.18,0,0,1,.18-.14h7.14a.18.18,0,0,1,.18.23L30.91,32.69a.18.18,0,0,1-.17.13H22.29a.19.19,0,0,1-.18-.14L19.92,23A.18.18,0,0,0,19.57,23Z"
      fill={color}
    />
    <path
      d="M37.49,13.18h8.26a.18.18,0,0,1,.18.18V32.64a.18.18,0,0,1-.18.18H37.49a.18.18,0,0,1-.18-.18V13.36A.18.18,0,0,1,37.49,13.18Z"
      fill={color}
    />
    <path
      d="M61.65,32.82H53.39a.18.18,0,0,1-.18-.18V18.16A.18.18,0,0,0,53,18h-6a.18.18,0,0,1-.18-.18V13.36a.18.18,0,0,1,.18-.18H68a.18.18,0,0,1,.18.18V17.8A.18.18,0,0,1,68,18H62a.18.18,0,0,0-.18.18V32.64A.18.18,0,0,1,61.65,32.82Z"
      fill={color}
    />
    <path
      d="M69.33,13.18h8.26a.18.18,0,0,1,.18.18v7.06a.18.18,0,0,0,.18.18H84a.18.18,0,0,0,.18-.18V13.36a.18.18,0,0,1,.18-.18h8.26a.18.18,0,0,1,.18.18V32.64a.18.18,0,0,1-.18.18H84.39a.18.18,0,0,1-.18-.18V25.36a.18.18,0,0,0-.18-.18H78a.18.18,0,0,0-.18.18v7.28a.18.18,0,0,1-.18.18H69.33a.18.18,0,0,1-.18-.18V13.36A.18.18,0,0,1,69.33,13.18Z"
      fill={color}
    />
    <path
      d="M108.13,25.68l-7.45-12.23a.18.18,0,0,1,.15-.27h8.42a.19.19,0,0,1,.15.09L113,19.82a.18.18,0,0,0,.31,0l3.64-6.56a.19.19,0,0,1,.15-.09h7a.18.18,0,0,1,.15.27L116.8,25.68a.17.17,0,0,0,0,.1v6.86a.18.18,0,0,1-.18.18h-8.26a.18.18,0,0,1-.18-.18V25.78A.3.3,0,0,0,108.13,25.68Z"
      fill={color}
    />
    <path
      d="M122.61,24.85v-3.7c0-5.13,3.6-8.08,10-8.08h3.93c6.44,0,10,2.95,10,8.08v3.7c0,5.13-3.6,8.08-10,8.08h-3.93C126.21,32.93,122.61,30,122.61,24.85Zm11.56,2.95H135c1.86,0,3-1.09,3-3.38V21.36c0-2.29-1.09-3.38-3-3.38h-.87c-1.85,0-2.95,1.09-2.95,3.38v3.06C131.22,26.71,132.32,27.8,134.17,27.8Z"
      fill={color}
    />
    <path
      d="M170,25.07c0,4.91-3.28,7.86-9.06,7.86h-4c-5.79,0-9.06-2.95-9.06-7.64V13.36a.18.18,0,0,1,.18-.18h8.26a.18.18,0,0,1,.18.18V25.07a2.47,2.47,0,0,0,2.62,2.73h1a2.47,2.47,0,0,0,2.62-2.73V13.36a.18.18,0,0,1,.18-.18h6.95a.18.18,0,0,1,.18.18Z"
      fill={color}
    />
  </svg>
);

Wordmark.defaultProps = {
  className: ``,
  color: `black`
};

Wordmark.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

export default Wordmark;
